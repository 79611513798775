<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import gql from "graphql-tag";
export default {
  data() {
    return {};
  },
  async mounted() {
    const response = await this.$apollo.query({
      query: gql`
        query {
          allUsers {
            id
            email
          }
        }
      `,
    });
    console.log(response.data.allUsers);
  },
};
</script>

<style lang="scss">
#app {
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
/*
  Enter and leave animations can use different
  durations and timing functions.
*/
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
