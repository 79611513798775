import Vue from "vue";
import VueRouter from "vue-router";
import AdminRouter from "@/admin/routes/Index.js";
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "main",
      component: () => import("@/main/Main.vue"),
      redirect: "home",
      children: [
        ...AdminRouter,
        // {
        //   path: "coming-soon",
        //   name: "coming-soon",
        //   component: () =>
        //     import("@/views/website/deevoir/pages/comingsoon/comingsoon.vue"),
        // },
        {
          path: "home",
          name: "home",
          component: () =>
            import("@/views/website/deevoir/pages/home/Home.vue"),
        },
        {
          path: "ourstory",
          name: "about",
          component: () =>
            import("@/views/website/deevoir/pages/about/About.vue"),
        },
        {
          path: "known",
          name: "known",
          component: () => import("@/views/client/components/Clientbanner.vue"),
        },
        {
          path: "key-service",
          name: "key-service",
          component: () =>
            import("@/views/website/deevoir/pages/keyservice/KeyService.vue"),
        },
        {
          path: "analytic",
          name: "analytic",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Analytic.vue"
            ),
        },
        {
          path: "branding",
          name: "branding",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Branding.vue"
            ),
        },
        {
          path: "capital",
          name: "capital",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Capital.vue"
            ),
        },
        {
          path: "estate",
          name: "estate",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Estate.vue"
            ),
        },
        {
          path: "export",
          name: "export",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Exports.vue"
            ),
        },
        {
          path: "human",
          name: "human",
          component: () =>
            import(
              "@/views/website/deevoir/pages/keyservice/components/Human.vue"
            ),
        },
        {
          path: "expertise",
          name: "expertise",
          component: () =>
            import("@/views/website/deevoir/pages/expertise/Expertise.vue"),
        },
        {
          path: "client",
          name: "client",
          component: () =>
            import("@/views/website/deevoir/pages/client/Client.vue"),
        },
        {
          path: "team",
          name: "team",
          component: () =>
            import("@/views/website/deevoir/pages/team/Team.vue"),
        },
        {
          path: "talk",
          name: "talk",
          component: () =>
            import("@/views/website/deevoir/pages/talk/Talk.vue"),
        },
        {
          path: "contact",
          name: "connect",
          component: () =>
            import("@/views/website/deevoir/pages/connect/Connect.vue"),
        },
        //digitalservice
        {
          path: "digital-home",
          name: "digital-home",
          component: () =>
            import("@/views/website/digitalservice/pages/home/Home.vue"),
        },
        {
          path: "digital-leading",
          name: "digital-leading",
          component: () =>
            import("@/views/website/digitalservice/pages/leading/Leading.vue"),
        },
        {
          path: "digital-service",
          name: "digital-service",
          component: () =>
            import(
              "@/views/website/digitalservice/pages/services/Services.vue"
            ),
        },
        //hrservices
        {
          path: "hr-home",
          name: "hr-home",
          component: () =>
            import("@/views/website/hrservice/pages/home/Home.vue"),
        },
        {
          path: "hr-about",
          name: "hr-about",
          component: () =>
            import("@/views/website/hrservice/pages/about/About.vue"),
        },
        {
          path: "hr-talk",
          name: "hr-talk",
          component: () =>
            import("@/views/website/hrservice/pages/talk/Talk.vue"),
        },
        {
          path: "hr-team",
          name: "hr-team",
          component: () =>
            import("@/views/website/hrservice/pages/team/Team.vue"),
        },
        {
          path: "hr-connect",
          name: "hr-connect",
          component: () =>
            import("@/views/website/hrservice/pages/connect/Connect.vue"),
        },
        {
          path: "hr-client",
          name: "hr-client",
          component: () =>
            import("@/views/website/hrservice/pages/client/Client.vue"),
        },
        {
          path: "hr-vision",
          name: "hr-vision",
          component: () =>
            import("@/views/website/hrservice/pages/vision/Vission.vue"),
        },
        {
          path: "hr-service",
          name: "hr-service",
          component: () =>
            import("@/views/website/hrservice/pages/service/Service.vue"),
        },
        // {
        //   path: "coming-soon",
        //   name: "coming-soon",
        //   component: () =>
        //     import("@/views/website/deevoir/pages/comingsoon/Comingsoon.vue"),
        // },
      ],
    },

    //dark navbar
    // {
    //   path: "/channel",
    //   name: "maindark",
    //   component: () => import("@/main/MainDark.vue"),
    //   redirect: "channel-discussion",
    //   children: [],
    // },
  ],
});
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});
export default router;
