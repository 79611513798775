export default [
  {
    path: "/admin",
    name: "admin",
    component: () => import("@/admin/layouts/Admin.vue"),
    children: [
      {
        path: "home",
        name: "admin-home",
        component: () => import("@/admin/pages/home/Home.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-product",
        name: "admin-addproduct",
        component: () => import("@/admin/pages/add-product/AddProduct.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "vendor-listing",
        name: "e-commerce-admin-vendorlisting",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addtags" */ "../pages/vendor-listing/VendorListing.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "vendor-transactions",
        name: "e-commerce-admin-vendortransactions",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addtags" */ "../pages/vendor-transactions/VendorTransactions.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-sub-category",
        name: "e-commerce-admin-addsubcategory",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addcategory" */ "../pages/add-sub-category/AddSubCategory.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-commission",
        name: "e-commerce-admin-commission",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addcategory" */ "../pages/commission/Commission.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-couponcode",
        name: "e-commerce-admin-couponcode",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addcategory" */ "../pages/coupon-code/CouponCode.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-subscriptions",
        name: "e-commerce-admin-subscriptions",
        component: () =>
          import(
            /* webpackChunkName: "e-commerce-admin-addcategory" */ "../pages/subscriptions/Subscriptions.vue"
          ),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-page",
        name: "admin-page",
        component: () => import("@/admin/pages/add-pages/AddPage.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-banner",
        name: "admin-addbanner",
        component: () => import("@/admin/pages/add-banner/AddBanner.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-tags",
        name: "admin-addtags",
        component: () => import("@/admin/pages/add-tags/AddTags.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "add-category",
        name: "admin-addcategory",
        component: () => import("@/admin/pages/add-category/AddCategory.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },

      {
        path: "add-menu",
        name: "admin-menu",
        component: () => import("@/admin/pages/add-menu/AddMenu.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "sales-report",
        name: "admin-reports",
        component: () => import("@/admin/pages/sales-report/SalesReport.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "taxes",
        name: "admin-taxes",
        component: () => import("@/admin/pages/taxes/Taxes.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "subscriberplan",
        name: "admin-subscriberplan",
        component: () =>
          import("@/admin/pages/add-subscriber/SubscriberPlan.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "analytics",
        name: "admin-analytics",
        component: () => import("@/admin/pages/analytics/Analytics.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "addnewpage",
        name: "admin-addnewpage",
        component: () =>
          import("@/admin/pages/add-pages/components/NewPage.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "integrations",
        name: "integrations",
        component: () => import("@/admin/pages/integrations/integrations.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "custom-duty",
        name: "custom-duty",
        component: () => import("@/admin/pages/custom-duty/CustomDuty.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "order-management",
        name: "order-management",
        component: () => import("@/admin/pages/order-mgmt/OrderMgmt.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "customer-management",
        name: "customer-management",
        component: () => import("@/admin/pages/cust-mgmt/CustomerMgmt.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "shipway-integration",
        name: "shipway-integration",
        component: () =>
          import("@/admin/pages/shipway-integration/ShipwayIntegration.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "contact-us",
        name: "contact-us",
        component: () => import("@/admin/pages/contact-us/ContactUs.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "manage-seller-review",
        name: "seller-review",
        component: () => import("@/admin/pages/seller-review/SellerReview.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "posts",
        name: "posts",
        component: () => import("@/admin/pages/post/Post.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "user",
        name: "user",
        component: () => import("@/admin/pages/user/User.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "channel",
        name: "channel",
        component: () => import("@/admin/pages/channel/Channel.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "admin-analyticss",
        name: "admin-analyticss",
        component: () =>
          import("@/admin/pages/admin-analytics/SalesReport.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "campaign-list",
        name: "campaign-list",
        component: () => import("@/admin/pages/campaign-list/Campaigns.vue"),

        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "logo",
        name: "logo",
        component: () =>
          import(
            /* webpackChunkName: "social-admin-logo" */ "../pages/logo/Logo.vue"
          ),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "scope",
        name: "scope",
        component: () => import("@/admin/pages/scope/Scope.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
      {
        path: "My Account",
        name: "myaccount",
        component: () => import("@/admin/pages/myaccount/MyAccount.vue"),
        meta: {
          rule: "editor",
          authRequired: true,
        },
      },
    ],
    meta: {
      rule: "editor",
      authRequired: true,
    },
  },
];
