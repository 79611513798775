import Vue from "vue";
import router from "@/router";
import App from "./App.vue";
import "@/assets/scss/Index.scss";
import VuePageTransition from "vue-page-transition";
import ElementUI from "element-ui";
Vue.use(ElementUI);
import "element-ui/lib/theme-chalk/index.css";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
import "@/assets/scss/argon.scss";
AOS.init();
Vue.use(VuePageTransition);
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
import Vuesax from "vuesax";
import DashboardPlugin from "./plugins/dashboard-plugin";
import apollo from "./apolloClient";
import FeatherIcon from "@/components/FeatherIcon.vue";
Vue.component(FeatherIcon.name, FeatherIcon);
Vue.use(DashboardPlugin);
// Vue.use(VueMaterial);
// import VueQuillEditor from "vue-quill-editor";

// import "quill/dist/quill.core.css"; // import styles
// import "quill/dist/quill.snow.css"; // for snow theme
// import "quill/dist/quill.bubble.css"; // for bubble theme


import "vuesax/dist/vuesax.css"; //Vuesax styles
Vue.use(Vuesax, {
  // options here
});
require("vue2-animate/dist/vue2-animate.min.css");
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);
import "./assets/scss/tailwind.css";
Vue.config.productionTip = false;
Vue.prototype.$apollo = apollo;
// Vuex Store
import store from "./store/index.js";

new Vue({
  store,
  router,
  Vuesax,
  render: (h) => h(App),
}).$mount("#app");
